














































































































import { Component, Vue } from 'vue-property-decorator';
import DocumentsService from '@/services/documentsService';
import { PhotoFile, Album } from '@/services';
import moment from 'moment';
import AlbumService from '@/services/albumService';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const loginNamespace = namespace(moduleTypes.LOGIN);

@Component({
	components: {}
})
export default class Galeria extends Vue {
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;

	photos: {
		src: string;
		thumbnail: string;
		w: number;
		h: number;
	}[] = [];

	fab = false;
	albumTitle = '';
	dialog = false;
	fab2 = false;

	albums: Album[] = [];

	loading = false;

	// eslint-disable-next-line
	onScroll(e: any) {
		if (typeof window === 'undefined') return;
		const top = window.pageYOffset || e.target.scrollTop || 0;
		this.fab = top > 20;
	}
	toTop() {
		this.$vuetify.goTo(0);
	}

	async addAlbum() {
		if (!this.albumTitle) return;

		await AlbumService.setAlbum(
			Album.create({ title: this.albumTitle, numberOfPhotos: 0 })
		).then(() => {
			this.getAlbums();
			this.dialog = false;
		});
	}

	async getAlbums() {
		this.loading = true;
		this.albums = [];
		await AlbumService.getAlbums()
			.then(async response => {
				const data: Album[] = [];
				if (response) {
					Object.keys(response).forEach(key => {
						data.push(response[key]);
					});
				}
				data.map(async d => {
					const photos = await this.loadPhotos(d._id);
					d.photos = photos ?? [];
					this.albums.push(d);
				});
				return data;
			})
			.then(() => {
				// this.albums = res;
				this.loading = false;
			});
	}

	async loadPhotos(albumId: string) {
		const data: PhotoFile[] = [];
		await DocumentsService.getPhotosWithLimit(albumId, 7).then(response => {
			if (response) {
				Object.keys(response).forEach(key => {
					data.push(response[key]);
				});
			}
			data.sort(
				(a, b) =>
					moment(b.metadata.trackingInfo.modified)
						.toDate()
						.getTime() -
					moment(a.metadata.trackingInfo.modified)
						.toDate()
						.getTime()
			).map(d => {
				return {
					src: d.link,
					thumbnail: d.link,
					w: d.width,
					h: d.height
				};
			});
		});
		return data;
	}

	mounted() {
		this.getAlbums();
	}
}
