








import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import {
	getterTypes as homeGetterTypes,
	actionTypes as homeActionTypes,
	HomeState
} from '@/store/modules/home';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';
const homeNamespace = namespace(moduleTypes.HOME);

@Component({
	components: {}
})
export default class ThemeToggler extends Vue {
	@homeNamespace.Getter(homeGetterTypes.HOME_DATA) homeData: HomeState;
	@homeNamespace.Action(homeActionTypes.SAVE_HOME_DATA) saveHomeData: (
		homeData: HomeState
	) => void;

	get isDark() {
		return this.$vuetify.theme.dark;
	}

	toggleTheme() {
		this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
		this.homeData.darkMode = this.$vuetify.theme.dark;
		this.saveToSession();
	}

	saveToSession() {
		this.saveHomeData(this.homeData);
	}
}
