import { Module } from 'vuex';
import { getters } from '@/store/modules/home/getters';
import { mutations } from '@/store/modules/home/mutations';
import { actions } from '@/store/modules/home/actions';
import { HomeState } from '@/store/modules/home/types';
import { RootState } from '@/store/types';
import { Metadata } from '@/services';

export * from './types';
export * from './getters';
export * from './mutations';
export * from './actions';

export const state: HomeState = {
	text: {
		_id: '1',
		metadata: new Metadata(),
		key: 'mainText',
		value:
			'Vítam vás na mojej stránke. Na prvú Vašu otázku odpoviem hneď Čo znamená názov Annomiss a prečo som si ho vybrala. Annomiss je Simona odzadu teda moje krstné meno a zároveň je to spojenie Talianskeho a Anglického slova Anno Miss - Rok slečny Nájdete tu všetky moje práce, make-up premeny, fotky od fotografov, moje nevesty, spolupráce. v menu nájdete galériu, cenník, objednávkový formulár, kontakty aj referencie. Prečo sa venujem práve sfére Make-up a ako som sa k nemu dostala? Písal sa zrovna máj 2017 moja maminka oslavovala svoje 66 narodeniny. na túto oslavu som ju celkom amatérsky namaľovala a maminka bola aj z takej obyčajnej premeny veľmi potešená. V ten deň ma prvý krát napadlo že toto by som rada robila. Ukázala ženám že sú krásne a môžu byť ešte krajšie. mala som 24 rokov a o líčení som nevedela zhola nič. Bohužiaľ tento rok nebol len o začiatkoch ale hlavne o konci :( Moja maminka 12.8.2017 navždy odišla presne 2 dni po mojich 25 narodeninách. A teda mi to prišlo ako taký odkaz na ten krásny deň jej oslavy kedy sme boli celá rodina naposledy spolu. V októbri som teda nastúpila na môj prvý kurz v Bratislave. V roku 2019 som prešla úžasným kurzom v akadémií Jany Olšinskej Štúdio 22 a bolo to jedno z najlepších rozhodnutí. A len pár dní do zadu som otvorila svoj vytúžený salón v Meste Senica. kde prebiehajú kurzy líčenia aj samolíčenia. Za mojimi nevestami cestujem ale po celom Slovensku takže v prípade záujmu sa neváhajte objednať.'
	},
	texts: [],
	mainLoading: false,
	themes: {
		light: {
			background: '#f5f5f5',
			primary: '#ee44aa',
			secondary: '#424242',
			accent: '#82B1FF',
			error: '#FF5252',
			info: '#2196F3',
			success: '#4CAF50',
			warning: '#FFC107',
			logo: '#ee44aa'
		},
		dark: {
			background: '#121212',
			primary: '#d4af34',
			secondary: '#393939',
			accent: '#FFFFFF',
			error: '#FF5252',
			info: '#2196F3',
			success: '#4CAF50',
			warning: '#FFC107',
			logo: '#FFFFFF'
		}
	}
};

export const home: Module<HomeState, RootState> = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
