

























import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { moduleTypes } from '@/store/types';
import TheFooter from '@/components/layouts/default/TheFooter.vue';
import TheAppBar from '@/components/layouts/default/TheAppBar.vue';

const loginNamespace = namespace(moduleTypes.LOGIN);
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const homeNamespace = namespace(moduleTypes.HOME);
import {
	actionTypes as homeActionTypes,
	getterTypes as homeGetterTypes,
	HomeState
} from '@/store/modules/home';
import SettingsService, { Settings } from '@/services/settingsService';

@Component({
	components: { TheFooter, TheAppBar }
})
export default class DefaultLayout extends Vue {
	@loginNamespace.Getter(loginGetterTypes.IS_EDITOR) isEditor: boolean;
	@loginNamespace.Getter(loginGetterTypes.IS_ADMIN) isAdmin: boolean;
	@homeNamespace.Getter(homeGetterTypes.HOME_DATA) homeData: HomeState;

	@homeNamespace.Action(homeActionTypes.SAVE_HOME_DATA) saveHomeData: (
		homeData: HomeState
	) => void;

	@homeNamespace.Action(homeActionTypes.LOAD_TEXTS) loadTexts: () => Promise<void>;

	get darkMode() {
		return this.$vuetify.theme.dark;
	}

	async mounted() {
		// load from local storage
		this.$vuetify.theme.themes.light = this.homeData.themes.light;
		this.$vuetify.theme.themes.dark = this.homeData.themes.dark;

		// load from db
		const settings: Settings | undefined = await SettingsService.getSettings();
		if (settings) {
			this.$vuetify.theme.themes.light = settings.themes.light;
			this.$vuetify.theme.themes.dark = settings.themes.dark;
			this.$vuetify.theme.dark = settings.dark;

			this.homeData.themes = settings.themes;
		}

		// set dark again if user has it in local storage
		if (this.homeData.darkMode !== undefined) {
			this.$vuetify.theme.dark = this.homeData.darkMode ?? true;
		}

		await this.loadTexts();
		this.homeData.mainLoading = true;

		// save to local storage
		this.saveHomeData(this.homeData);
	}
}
