

































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import TextService, { TextData } from '@/services/textService';
import { POSITION } from 'vue-toastification';
import { actionTypes as homeActionsTypes } from '@/store/modules/home/types';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const loginNamespace = namespace(moduleTypes.LOGIN);
const homeNamespace = namespace(moduleTypes.HOME);

@Component({
	components: {}
})
export default class TextEditor extends Vue {
	@Prop({ required: true }) text: TextData;
	@Prop({ default: 'textarea' }) type: 'textarea' | 'text';
	@homeNamespace.Action(homeActionsTypes.LOAD_TEXTS) loadTexts: () => Promise<void>;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;

	editMainText = false;

	newText = '';

	async saveText() {
		this.text.value = this.newText;
		await TextService.updateTextById(this.text._id, this.text).then(async () => {
			this.$toast.success('Text aktualizovaný', {
				position: POSITION.BOTTOM_RIGHT,
				timeout: 2000
			});
			await this.loadTexts();
		});
		this.editMainText = false;
	}
	@Watch('editMainText')
	onEditMainTextChange() {
		// console.log('text change');
		this.newText = this.text.value;
	}
}
