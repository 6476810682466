import { LoginState, actionTypes, mutationTypes } from './types';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import UsersService from '@/services/usersService';
import { User } from '@/services';

export const actions: ActionTree<LoginState, RootState> = {
	async [actionTypes.TOGGLE_LOGIN](
		{ commit },
		payload: { id: string; email: string }
	): Promise<void> {
		await UsersService.getUserInfo(payload.id).then(async res => {
			if (!res) {
				const newUser = User.create({
					email: payload.email
				});
				await UsersService.createUser(payload.id, newUser);
				res = newUser;
				commit(mutationTypes.SET_RESET_PASSWORD, true);
			} else {
				commit(mutationTypes.SET_RESET_PASSWORD, false);
				res.lastLogin = new Date().toISOString();
				await UsersService.updateUserById(payload.id, res);
			}
			commit(mutationTypes.SET_USER_INFO, res);
			commit(mutationTypes.SET_LOGIN, true);
			commit(mutationTypes.SET_NUMBER_OF_INCORRECT_LOGINS, 0);
		});
	},
	async [actionTypes.LOGOUT]({ commit }): Promise<void> {
		await UsersService.logoutFirebase().then(() => {
			commit(mutationTypes.SET_LOGIN, false);
			commit(mutationTypes.SET_USER_INFO, undefined);
		});
	},
	async [actionTypes.RESET_PASSWORD]({ commit }) {
		// action after seccessfull reset password - set passwordReset to false
		const user = UsersService.getActualUser();
		if (user?.uid) {
			await UsersService.getUserInfo(user.uid).then(async res => {
				await UsersService.updateUserById(
					user.uid,
					Object.assign(res, { passwordReset: false })
				);
			});
			commit(mutationTypes.SET_RESET_PASSWORD, false);
		}
	},
	[actionTypes.CHANGE_NUMBER_OF_INCORRECT_LOGINS]({ commit }, payload: number): void {
		commit(mutationTypes.SET_NUMBER_OF_INCORRECT_LOGINS, payload);
	}
};
