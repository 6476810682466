















































































































import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';

import {
	getterTypes as webInfoGetterTypes,
	WebInfoState
} from '../../../store/modules/webInfo/types';
import { moduleTypes } from '../../../store/types';
import { namespace } from 'vuex-class';
import FileReplacer from '@/components/file/FileReplacer.vue';
import ThemeToggle from '@/components/ThemeToggle.vue';
import { getterTypes as homeGettersTypes, HomeState } from '@/store/modules/home';
import { TextData } from '@/services';
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const loginNamespace = namespace(moduleTypes.LOGIN);

const webInfoNamespace = namespace(moduleTypes.WEB_INFO);
const homeNamespace = namespace(moduleTypes.HOME);

@Component({
	components: { FileReplacer, ThemeToggle }
})
export default class TheAppBar extends Vue {
	@webInfoNamespace.Getter(webInfoGetterTypes.WEB_INFO) webInfo: WebInfoState;
	@homeNamespace.Getter(homeGettersTypes.TEXT_BY_KEY) textByKey: (key: string) => TextData | null;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;
	@homeNamespace.Getter(homeGettersTypes.HOME_DATA) homeData: HomeState;

	loadedImage = false;

	get titleheight() {
		if (this.$vuetify.breakpoint.mdAndUp) return '150px';
		return undefined;
	}

	textField(key: string) {
		return this.textByKey(key);
	}

	menus = [
		{ title: 'O mne', to: '/' },
		{ title: 'Galéria', to: '/galeria' },
		{ title: 'Cenník', to: '/cennik' },
		{ title: 'Kurzy', to: '/kurzy' },
		{ title: 'Objednávkový formulár', to: '/objednavky' },
		{ title: 'Admin', to: '/admin' }
	];

	imageSrc = require('@/assets/imgs/white.png'); // Your PNG file
	get selectedColor(): string {
		if (this.$vuetify.theme.dark)
			return (this.$vuetify.theme.themes.dark.logo as string) ?? '#000000';
		return (this.$vuetify.theme.themes.light.logo as string) ?? '#ffffff';
	}

	@Watch('selectedColor')
	onSelectedColorChange() {
		this.recolorImage();
	}

	get mainLoading() {
		return this.homeData.mainLoading;
	}

	@Watch('mainLoading')
	onMainLoadingChange() {
		this.recolorImage();
	}

	recolorImage() {
		this.loadedImage = false;
		const img = new Image();
		img.src = this.imageSrc;
		img.crossOrigin = 'Anonymous';
		img.onload = () => {
			// eslint-disable-next-line
			const canvas: any = this.$refs.canvas;
			const ctx = canvas.getContext('2d');

			const canvasWidth = 100; // Set canvas size
			const canvasHeight = 66;
			canvas.width = canvasWidth;
			canvas.height = canvasHeight;

			// Calculate "contain" mode dimensions
			const imgRatio = img.width / img.height;
			const canvasRatio = canvasWidth / canvasHeight;
			let drawWidth, drawHeight, offsetX, offsetY;

			if (imgRatio > canvasRatio) {
				// Image is wider than canvas
				drawWidth = canvasWidth;
				drawHeight = canvasWidth / imgRatio;
				offsetX = 0;
				offsetY = (canvasHeight - drawHeight) / 2;
			} else {
				// Image is taller than canvas
				drawWidth = canvasHeight * imgRatio;
				drawHeight = canvasHeight;
				offsetX = (canvasWidth - drawWidth) / 2;
				offsetY = 0;
			}

			ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
			ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight); // Draw resized image

			// Get image pixels
			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			const data = imageData.data;

			// Convert selected color to RGB
			const rNew = parseInt(this.selectedColor.slice(1, 3), 16);
			const gNew = parseInt(this.selectedColor.slice(3, 5), 16);
			const bNew = parseInt(this.selectedColor.slice(5, 7), 16);

			for (let i = 0; i < data.length; i += 4) {
				if (data[i + 3] > 0) {
					// Ignore transparent pixels
					data[i] = rNew; // Red
					data[i + 1] = gNew; // Green
					data[i + 2] = bNew; // Blue
				}
			}

			ctx.putImageData(imageData, 0, 0);
			this.loadedImage = true;
		};
	}
}
