import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: true,
		options: {
			customProperties: true
		},
		themes: {
			light: {
				accent: '#880E4F',
				background: '#FFCDD2',
				logo: '#FFFFFF',
				primary: '#ee44aa',
				secondary: '#FFCDD2',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107'
			},
			dark: {
				accent: '#FFFFFF',
				background: '#121212',
				logo: '#FFFFFF',
				primary: '#d4af34',
				secondary: '#393939',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107'
			}
		}
	},
	icons: {
		iconfont: 'mdiSvg'
	}
});
