import { MutationTree } from 'vuex';
import { HomeState, mutationTypes } from '@/store/modules/home/types';
import { TextData } from '@/services';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';

export const mutations: MutationTree<HomeState> = {
	[mutationTypes.SAVE_TEXTS](state, data: TextData[]) {
		state.texts = data;
	},
	[mutationTypes.SET_DARK_MODE](state, value: boolean) {
		state.darkMode = value;
	},
	[mutationTypes.SAVE_THEMES](
		state,
		themes: { dark: VuetifyThemeVariant; light: VuetifyThemeVariant }
	) {
		state.themes = themes;
	}
};
