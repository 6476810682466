import { Module } from 'vuex';
import { getters } from '@/store/modules/webInfo/getters';
import { mutations } from '@/store/modules/webInfo/mutations';
import { WebInfoState } from '@/store/modules/webInfo/types';
import { RootState } from '@/store/types';

export const state: WebInfoState = {
	link: 'annomissart.sk',
	mail: 'skvarkovasimona@gmail.com',
	// mail: 'fkopernicky@gmail.com',
	name: 'AnnomissArt Maľovanie na tvár',
	startYear: 2018,
	facebook: 'https://www.facebook.com/AnnomissArtBeauty/',
	instagram: 'https://www.instagram.com/annomissart_beauty/',
	phone: '0944 965 346'
};

export const webInfo: Module<WebInfoState, RootState> = {
	namespaced: true,
	state,
	getters,
	mutations
};
