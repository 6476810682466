var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kurzy mt-2",staticStyle:{"min-height":"700px"}},[_c('h1',[_vm._v("Kurzy")]),_c('v-divider',{staticClass:"mb-8"}),(_vm.isLogged)?_c('v-btn',{staticClass:"primary mb-6",attrs:{"block":""},on:{"click":_vm.addNewKurz}},[_c('v-icon',{staticClass:"pr-4",attrs:{"small":""}},[_vm._v("fa-plus")]),_vm._v("Pridat kurz")],1):_vm._e(),_vm._l((_vm.curses),function(n,index){return _c('v-row',{key:index,staticClass:"pb-12",staticStyle:{"min-height":"300px"},attrs:{"dense":"","no-gutters":""}},[_c('v-col',{class:{
				'order-1': (index + 1) % 2 && !_vm.$vuetify.breakpoint.smAndDown
			},attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-img',{attrs:{"src":n.photo.value,"min-height":"100%","cover":"","max-height":_vm.$vuetify.breakpoint.smAndDown ? '200px' : '300px',"position":"right"}}),(_vm.isLogged)?_c('file-replacer',{attrs:{"fileWay":n.photo}}):_vm._e()],1)],1),_c('v-col',{class:{
				'order-0': (index + 1) % 2 && !_vm.$vuetify.breakpoint.smAndDown
			},attrs:{"cols":"12","md":"8"}},[_c('v-card',{class:{
					'px-5 py-5 mx-0': true,
					'mr-md-6': (index + 1) % 2 && !_vm.$vuetify.breakpoint.smAndDown,
					'ml-md-6': !((index + 1) % 2 && !_vm.$vuetify.breakpoint.smAndDown)
				},attrs:{"color":"secondary"}},[(_vm.isLogged)?_c('v-btn',{attrs:{"color":"error","block":""},on:{"click":function($event){return _vm.deleteCurs(n.title, n.text, n.photo)}}},[_c('v-icon',{staticClass:"pr-4",attrs:{"small":""}},[_vm._v("fa-trash-alt")]),_vm._v("Vymazať kurz ")],1):_vm._e(),_c('text-editor',{attrs:{"text":n.title,"type":"text"}}),_c('v-card-title',{staticClass:"display-1"},[_vm._v(_vm._s(n.title.value))]),_c('v-divider'),_c('text-editor',{attrs:{"text":n.text}}),_c('v-card-text',{staticClass:"text-justify",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(n.text.value)+" ")]),_c('v-divider'),_c('v-card-actions',{class:{
						'pt-4 pb-0 px-0': true,
						'd-flex flex-column flex-md-row': _vm.$vuetify.breakpoint.smAndDown
					}},[_c('v-btn',{attrs:{"min-width":"200","block":_vm.$vuetify.breakpoint.smAndDown,"to":"/galeria","color":"primary"}},[_vm._v("Ukážky práce")]),_c('v-spacer'),_c('v-btn',{class:{
							'mx-0 mx-md-4 mt-4 mt-md-0': _vm.$vuetify.breakpoint.smAndDown
						},attrs:{"min-width":"200","block":_vm.$vuetify.breakpoint.smAndDown,"to":"/objednavky","color":"primary"}},[_vm._v("Objednat kurz")])],1)],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }