import { TextData } from '@/services';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';

export interface HomeState {
	text: TextData;
	texts: TextData[];
	darkMode?: boolean;
	themes: {
		dark: VuetifyThemeVariant;
		light: VuetifyThemeVariant;
	};
	mainLoading: boolean;
}

export const actionTypes = {
	LOAD_TEXTS: 'loadTexts',
	SAVE_HOME_DATA: 'saveHomeData'
};

export const mutationTypes = {
	SAVE_TEXTS: 'saveTexts',
	SET_DARK_MODE: 'setDarkMode',
	SAVE_THEMES: 'saveThemes'
};

export const getterTypes = {
	HOME_DATA: 'homeData',
	TEXT_BY_KEY: 'textByKey'
};
