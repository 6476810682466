





















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import FileService from '@/services/fileService';
import { FileUploadResponse, TextData } from '@/services';
import TextService from '@/services/textService';
import { actionTypes as homeActionsTypes } from '@/store/modules/home/types';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const loginNamespace = namespace(moduleTypes.LOGIN);
const homeNamespace = namespace(moduleTypes.HOME);

@Component({
	components: {}
})
export default class FileReplacer extends Vue {
	@Prop({ required: true }) fileWay: TextData;
	@homeNamespace.Action(homeActionsTypes.LOAD_TEXTS) loadTexts: () => Promise<void>;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;

	imgFile: File | null = null;

	isEditor = true;
	fileuploading = false;

	replaceFile() {
		// eslint-disable-next-line
		document.getElementById('uploadInput' + this.fileWay.key)!.click();
	}

	async onFileChanged() {
		// console.log(this.fileWay.key);

		if (this.imgFile !== null) {
			const way = `../../data/photos/web/${this.fileWay.key}/`;
			this.fileuploading = true;

			await FileService.uploadFile(this.imgFile, way)
				.then(async res => {
					if (!res.data.data.fileUpload)
						throw {
							data: res.data.data,
							error: new Error()
						};
					await FileService.deleteFile(this.fileWay.value);
					// eslint-disable-next-line
					this.saveText(way + this.imgFile!.name);
				})
				.catch(err => {
					const error = err as {
						data: FileUploadResponse;
						error: Error;
					};
					let errorMsg = 'error.file.not-save';
					if (!error.data.fileInfo.size) errorMsg = 'error.file.size';
					if (!error.data.fileInfo.extension) errorMsg = 'error.file.extension';
					if (!error.data.fileInfo.type) errorMsg = 'error.file.type';
					this.$toast.error(this.$t(errorMsg));
					this.fileuploading = false;
					throw Error();
				});
		}
	}

	async saveText(way: string) {
		// console.log(this.fileWay.key);

		this.fileWay.value = way;
		await TextService.updateTextById(this.fileWay._id, this.fileWay).then(async () => {
			await this.loadTexts();
		});
	}
}
