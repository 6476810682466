











































































import { Component, Vue, Watch } from 'vue-property-decorator';
import SettingsService, { Settings } from '@/services/settingsService';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';
const loginNamespace = namespace(moduleTypes.LOGIN);
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';

@Component({
	components: {}
})
export default class About extends Vue {
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;
	selectedColor = '';
	selectedName = '';
	selectedDark = false;
	settings: Settings | null = null;

	dialog = false;

	get colorsWhite() {
		return this.$vuetify.theme.themes.light;
	}
	get colorsDark() {
		return this.$vuetify.theme.themes.dark;
	}

	async setColor() {
		if (this.selectedDark)
			this.$vuetify.theme.themes.dark[this.selectedName] = this.selectedColor;
		else this.$vuetify.theme.themes.light[this.selectedName] = this.selectedColor;

		await this.saveSettings();

		this.dialog = false;
	}

	async saveSettings(dark?: boolean) {
		if (!this.settings) {
			await SettingsService.setSettings(
				Settings.create({
					themes: this.$vuetify.theme.themes,
					dark: dark ?? this.$vuetify.theme.dark
				})
			);
			return;
		}

		this.settings.themes = this.$vuetify.theme.themes;
		this.settings.dark = dark ?? this.$vuetify.theme.dark;

		await SettingsService.updateSettingsById(this.settings._id, this.settings);
	}

	async toggleTheme() {
		await this.saveSettings(!this.settings?.dark ?? true);
	}

	async resetThemes() {
		this.$vuetify.theme.themes.light = {
			accent: '#880E4F',
			background: '#FFCDD2',
			logo: '#FFFFFF',
			primary: '#ee44aa',
			secondary: '#FFCDD2',
			error: '#FF5252',
			info: '#2196F3',
			success: '#4CAF50',
			warning: '#FFC107'
		};
		this.$vuetify.theme.themes.dark = {
			accent: '#FFFFFF',
			background: '#121212',
			logo: '#FFFFFF',
			primary: '#d4af34',
			secondary: '#393939',
			error: '#FF5252',
			info: '#2196F3',
			success: '#4CAF50',
			warning: '#FFC107'
		};
		await this.saveSettings();
	}

	@Watch('isLogged')
	onIsLoggedChange() {
		if (!this.isLogged) this.$router.push('/');
	}

	async mounted() {
		// redirect to home if not logged in and open login dialog
		if (!this.isLogged) this.$router.push('/');
		this.settings = await SettingsService.getSettings();
	}
}
