import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import FirebaseService from './firebaseService';
import { BaseEntity, createGuid, Metadata, TrackingInfo } from './types';

export enum TablesNamesSettings {
	SETTINGS = 'settings'
}

export class Settings extends BaseEntity {
	themes: {
		dark: VuetifyThemeVariant;
		light: VuetifyThemeVariant;
	};
	dark: boolean;

	constructor(data?: Partial<Settings>) {
		super();
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}

	static create(data: Partial<Settings>): Settings {
		return new Settings({
			_id: data._id ?? createGuid(),
			metadata:
				data.metadata ||
				new Metadata({
					type: Settings.name,
					trackingInfo: new TrackingInfo({ created: new Date().toISOString() })
				}),
			themes: data.themes,
			dark: data.dark
		});
	}
}

export default class SettingsService extends FirebaseService {
	/////////////////////////////////////////
	//                Albums               //
	/////////////////////////////////////////

	static getSettings() {
		return this.getData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesSettings.SETTINGS}`
		).then(async response => {
			const data: Settings[] = [];
			if (response) {
				Object.keys(response).forEach(key => {
					data.push(response[key]);
				});
			}
			return data[0];
		});
	}

	static setSettings(data: Settings) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesSettings.SETTINGS}/${data._id}`,
			data
		);
	}
	static deleteSettings(textId: string) {
		return this.removeData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesSettings.SETTINGS}/${textId}`
		);
	}

	static updateSettingsById(id: string, data: Partial<Settings>) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesSettings.SETTINGS}/${id}`,
			data
		);
	}
}
